<template>
    <div class="wrapper">
        <div class="container-fluid">
            <!-- Page-Title -->
            <router-view></router-view>
            <!-- End container-fluid -->
            <Footer />
        </div>
    </div>
</template>

<script>
import Footer from '@/views/layouts/FooterComponent.vue';
export default {
    name: 'MainGrafik',
    components: {
        Footer
    },
    data() {
        return {
            title: 'Grafik'
        };
    },
};
</script>

<style lang="css" scoped>

</style>
