<template>
    <!-- Footer -->
    <footer class="footer text-right">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 text-center">
                    2022 © Bank Data Gizkia
                </div>
            </div>
        </div>
    </footer>
    <!-- End Footer -->
</template>

<script>
export default {
    name: 'FooterComponent',
    data() {
        return {

        };
    },
};
</script>

<style lang="css" scoped>
</style>
